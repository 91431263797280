import { useEffect, useState } from "react";
import { MainContext } from "../../contexts";
import Error from "../Error";
import Loading from "../Loading";
import LandingPage from "../LandingPage";
import "../../services/firebase";
import { app, replaceImagenInModel } from "../../services/firebase";
import { getFirestore } from "firebase/firestore";
import { collection, getDocs } from "firebase/firestore";

function Index() {
    const [width, setWidth] = useState(window?.innerWidth);
    const [languageActive, setLanguageActive] = useState("spanish");
    const [model, setModel] = useState();
    const [loading, setLoading] = useState();

    useEffect(() => {
        window.addEventListener("resize", () => setWidth(window.innerWidth));
        return () => {
            window.removeEventListener("resize", () => setWidth(window.innerWidth));
        };
    }, []);

    const isMobile = width < 600;
    const isTablet = width >= 600 && width < 768;
    const isDesktop = width >= 768;
    const isLocalhost = window.location.hostname.includes("localhost") || window.location.hostname.includes("127.0.0.1");

    const contextValue = { isMobile, isTablet, isDesktop, isLocalhost, languageActive, setLanguageActive };

    useEffect(() => {
        const fetchData = async () => {
            const db = getFirestore(app);
            setLoading(true);
            const querySnapshot = await getDocs(collection(db, "languages"));
            querySnapshot.forEach(async (doc) => {
                if (doc && doc.id === "spansh") {
                    await replaceImagenInModel(doc.data()).then((model) => {
                        setModel(model);
                        setLoading(false);
                    });
                }
            });
        };

        fetchData().catch(console.error);
    }, []);

    return (
        <MainContext.Provider value={contextValue}>
            {loading && <Loading />}
            {model && !loading && <LandingPage model={model} />}
            {!model && !loading && <Error />}
        </MainContext.Provider>
    );
}

export default Index;
