import styles from "./ReviewCard.module.scss";

function ReviewCard({ information, order }) {
    return (
        information && (
            <div className={styles.reviewCard}>
                {/* {information.image?.url && <img className={styles.imgReview} src={information.image.url} alt={information.image.alternativeText ?? "Image"} />} */}
                <div className={styles.cardContent}>
                    <span>
                        <i className="ri-double-quotes-l"></i>
                    </span>
                    <div className={styles.cardDetails}>
                        {information.paragraph && <p>"{information.paragraph}"</p>}
                        <h4>- {information.name}</h4>
                    </div>
                </div>
            </div>
        )
    );
}

export default ReviewCard;
