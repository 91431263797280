import styles from "./ServiceCard.module.scss";
import imgDefault from "../../assets/card-default.jpg";

function ServiceCard({ information, order }) {
    return (
        information && (
            <div className={styles.serviceCard}>
                <div className={styles.image}>
                    {information.image?.url && (
                        <img className={styles.imgCard} src={information.image.url} alt={information.image.alternativeText ?? "Image"} />
                    )}
                    {!information.image?.url && <img className={styles.imgCard} src={imgDefault} alt="imagen propuesta" />}
                </div>

                <div className={styles.cardBody}>
                    {information.heading && <h3 className={styles.heading}>{information.heading}</h3>}
                    {information.paragraph && <p dangerouslySetInnerHTML={{ __html: information.paragraph }} />}
                    {information.link && (
                        <a href={information.link} target="_blank" rel="noopener noreferrer">
                            <button className={styles.primaryBtn}>Más Info</button>
                        </a>
                    )}
                    {!information.link && (
                        <a href="#ourServices">
                            <button className={styles.primaryBtn} disabled>
                                Proximamente
                            </button>
                        </a>
                    )}
                </div>
            </div>
        )
    );
}

export default ServiceCard;
