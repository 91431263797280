import React from "react";
import clsx from "clsx";
// import { useContext } from "react";
// import { MainContext } from "../../contexts";
import ServiceCard from "../ServiceCard";
import Footer from "../Footer";
import Navbar from "../Navbar";
import styles from "./LandingPage.module.scss";
import ContactForm from "../ContactForm";
import ReviewCard from "../ReviewCard";
import bannerDesktop from "../../assets/banner-desktop.png";
import bannerMobile from "../../assets/banner-mobile.png";
import bannerMd from "../../assets/banner-md.png";

function LandingPage({ model }) {
    const encoded_message = model?.whatsapp?.message.replace(" ", "%20");
    return (
        <>
            <Navbar information={model.nav} languages={model.system?.languages} />

            <main>
                <section id="home" className={clsx(styles.firstScreen)}>
                    <article className={clsx(styles.imageContent)}>
                        <img src={bannerDesktop} alt={"banner-desktop"} className={styles.desktopImg} />
                        <img src={bannerMobile} alt={"banner-mobile"} className={styles.mobileImg} />
                        <img src={bannerMd} alt={"banner-md"} className={styles.mdImg} />
                    </article>

                    {model?.main && (
                        <article className={styles.titleSection}>
                            {model.main.heading && <h1>{model.main.heading}</h1>}
                            {model.main.text && <p dangerouslySetInnerHTML={{ __html: model.main.text }} />}
                            <a href="#getInTouch">
                                <button className={styles.primaryBtn}>Contactanos</button>
                            </a>
                        </article>
                    )}

                    <svg className={styles.arrow} data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                        <path d="M3.81 4.38 8 8.57l4.19-4.19 1.52 1.53L8 11.62 2.29 5.91l1.52-1.53z" />
                    </svg>
                </section>

                {model?.aboutUs && (
                    <section id="aboutUs" className={clsx(styles.aboutUs)}>
                        <div>
                            <img src={model?.aboutUs.image.url} alt={model?.aboutUs.image.alternatiText || "About us image"} className={styles.image} />
                        </div>

                        <div className={styles.information}>
                            <h2>{model.aboutUs.title ?? "QUIENES SOMOS"}</h2>
                            {model.aboutUs.paragraph && <p dangerouslySetInnerHTML={{ __html: model.aboutUs.paragraph }} />}
                        </div>
                    </section>
                )}

                {model?.ourServices && (
                    <section id="ourServices" className={clsx(styles.ourServices)}>
                        <h2>{model.ourServices.title ?? "PROPUESTAS"}</h2>
                        <article className={styles.serviceList}>
                            {model.ourServices.services.map((service, index) => {
                                return <ServiceCard key={index} order={index} information={service} />;
                            })}
                        </article>
                    </section>
                )}

                {model?.curso && (
                    <section id="curso" className={clsx(styles.curso)}>
                        {model.curso.title && <h2>{model.curso.title}</h2>}
                        {model.curso.paragraph && <p>{model.curso.paragraph}</p>}
                        {model.curso.url && (
                            <a href={model.curso.url} target="_blank" rel="noreferrer">
                                <button className={styles.primaryBtn} aria-labelledby={model.curso?.text}>
                                    {model.curso.buttonText ?? "Click here"}
                                </button>
                            </a>
                        )}
                    </section>
                )}

                {/* {model?.onlineShop && (
                    <section id="onlineShop" className={clsx(styles.onlineShop)}>
                        {model.onlineShop.image?.url && <img src={model.onlineShop.image.url} alt={model.onlineShop.image.alternativeText} />}
                        {model.onlineShop.url && (
                            <a href={model.onlineShop.url} target="_blank" rel="noreferrer">
                                <button className={styles.onlineShopButton} aria-labelledby={model.onlineShop?.text}>
                                    {model.onlineShop.text}
                                </button>
                            </a>
                        )}
                    </section>
                )} */}

                {model?.reviews && (
                    <section id="reviews" className={clsx(styles.reviews)}>
                        <h2>{model.reviews.title ?? "Opiniones"}</h2>
                        <article className={styles.serviceList}>
                            {model.reviews.reviews.map((review, index) => {
                                return <ReviewCard key={index} order={index} information={review} />;
                            })}
                        </article>
                    </section>
                )}

                {model?.getInTouch && (
                    <section id="getInTouch" className={clsx(styles.getInTouch)}>
                        <ContactForm localization={model?.getInTouch?.form} />
                    </section>
                )}
            </main>

            <Footer information={model?.footer} />

            <a href={"https://wa.me/" + model?.whatsapp?.number + "?text=" + encoded_message} target="_blank" rel="noreferrer" className={styles.whatsapp}>
                <svg fill="#fff" viewBox="0 0 308 308">
                    <g id="XMLID_468_">
                        <path
                            id="XMLID_469_"
                            d="M227.904,176.981c-0.6-0.288-23.054-11.345-27.044-12.781c-1.629-0.585-3.374-1.156-5.23-1.156
		c-3.032,0-5.579,1.511-7.563,4.479c-2.243,3.334-9.033,11.271-11.131,13.642c-0.274,0.313-0.648,0.687-0.872,0.687
		c-0.201,0-3.676-1.431-4.728-1.888c-24.087-10.463-42.37-35.624-44.877-39.867c-0.358-0.61-0.373-0.887-0.376-0.887
		c0.088-0.323,0.898-1.135,1.316-1.554c1.223-1.21,2.548-2.805,3.83-4.348c0.607-0.731,1.215-1.463,1.812-2.153
		c1.86-2.164,2.688-3.844,3.648-5.79l0.503-1.011c2.344-4.657,0.342-8.587-0.305-9.856c-0.531-1.062-10.012-23.944-11.02-26.348
		c-2.424-5.801-5.627-8.502-10.078-8.502c-0.413,0,0,0-1.732,0.073c-2.109,0.089-13.594,1.601-18.672,4.802
		c-5.385,3.395-14.495,14.217-14.495,33.249c0,17.129,10.87,33.302,15.537,39.453c0.116,0.155,0.329,0.47,0.638,0.922
		c17.873,26.102,40.154,45.446,62.741,54.469c21.745,8.686,32.042,9.69,37.896,9.69c0.001,0,0.001,0,0.001,0
		c2.46,0,4.429-0.193,6.166-0.364l1.102-0.105c7.512-0.666,24.02-9.22,27.775-19.655c2.958-8.219,3.738-17.199,1.77-20.458
		C233.168,179.508,230.845,178.393,227.904,176.981z"
                        />
                        <path
                            id="XMLID_470_"
                            d="M156.734,0C73.318,0,5.454,67.354,5.454,150.143c0,26.777,7.166,52.988,20.741,75.928L0.212,302.716
		c-0.484,1.429-0.124,3.009,0.933,4.085C1.908,307.58,2.943,308,4,308c0.405,0,0.813-0.061,1.211-0.188l79.92-25.396
		c21.87,11.685,46.588,17.853,71.604,17.853C240.143,300.27,308,232.923,308,150.143C308,67.354,240.143,0,156.734,0z
		 M156.734,268.994c-23.539,0-46.338-6.797-65.936-19.657c-0.659-0.433-1.424-0.655-2.194-0.655c-0.407,0-0.815,0.062-1.212,0.188
		l-40.035,12.726l12.924-38.129c0.418-1.234,0.209-2.595-0.561-3.647c-14.924-20.392-22.813-44.485-22.813-69.677
		c0-65.543,53.754-118.867,119.826-118.867c66.064,0,119.812,53.324,119.812,118.867
		C276.546,215.678,222.799,268.994,156.734,268.994z"
                        />
                    </g>
                </svg>
            </a>
        </>
    );
}

export default LandingPage;
