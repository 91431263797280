import { useState } from "react";
import styles from "./MasterClass.module.scss";
import { useForm } from "react-hook-form";
import { formStates } from "../../ContactForm";
import clsx from "clsx";
import { BeatLoader } from "react-spinners";
import emailjs from "emailjs-com";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import { addDoc, collection, getDocs, getFirestore, query, where } from "firebase/firestore";
import { app } from "../../../services/firebase-forms";
import { Bounce, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function MasterClass() {
    const [captchaToken, setCaptchaToken] = useState(false);
    const [captchaError, setCaptchaError] = useState(false);
    const [formState, setFormState] = useState(formStates.init);
    const [checked, setChecked] = useState(true);

    const isLocalhost = window.location.hostname.includes("localhost") || window.location.hostname.includes("127.0.0.1");

    const siteKey = process.env.REACT_APP_HCAPTCHA_SITEKEY;

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm();

    const notify = () => {
        toast.success("Tu inscripción se completó con éxito, te vamos a redireccionar a nuestro sitio web...", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
            onClose: () => (window.location.href = "https://nicelanguageacademy.com.ar/")
        });
    };
    function sendEmail(data) {
        if (captchaToken || isLocalhost) {
            captchaError && setCaptchaError(false);
            setFormState(formStates.loading);
            const templateParams = {
                name: data.name,
                lastname: data.lastname,
                email: data.email
            };

            saveUserData(data).then((response) => {
                if (response) {
                    emailjs
                        .send(
                            process.env.REACT_APP_EMAILJS_SERVICEID,
                            process.env.REACT_APP_EMAILJS_MASTERCLASS_TEMPLATEID,
                            templateParams,
                            process.env.REACT_APP_EMAILJS_USERID
                        )
                        .then(
                            (result) => {
                                setFormState(formStates.submitted);
                                notify();
                                setTimeout(() => {
                                    document.getElementById("masterclass-form").reset();
                                    setFormState(formStates.init);
                                }, 2000);
                            },
                            (error) => {
                                console.error(error.text);
                            }
                        );
                } else {
                    setFormState(formStates.init);
                }
            });
        } else {
            setCaptchaError(true);
            console.error("Captcha incorrect");
        }
    }

    async function saveUserData(data) {
        const db = getFirestore(app);

        const q = query(collection(db, "masterclass-hit-the-road"), where("email", "==", data.email));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.docs.length) return await addDoc(collection(db, "masterclass-hit-the-road"), { ...data, date: Date.now() });

        return false;
    }

    return (
        <main>
            <ToastContainer />
            <section className={styles.header}>
                <div className={styles.imageContainer}>
                    <img className={styles.imageHeader} src="./assets/header-image.jpg" alt="Travelers" />
                </div>
                <div className={styles.headerContainer}>
                    <h1>MASTERCLASS</h1>
                    <h2>HIT THE ROAD: Inglés para Viajeros</h2>
                </div>
            </section>
            <section className={styles.formSection}>
                <div className={styles.paragraph}>
                    <span>
                        ¡Muchas gracias por confiar en <b>NICE Language Academy</b>!
                    </span>

                    <span>
                        Nos complace invitarte a nuestra exclusiva <b>MASTERCLASS</b>. Para asegurar tu lugar, por favor completa el formulario que encontrarás
                        a continuación.
                    </span>

                    <span>
                        <b>¡Te esperamos!</b>
                    </span>
                </div>
                <form id="masterclass-form" onSubmit={handleSubmit(sendEmail)}>
                    <div className={styles.row}>
                        <label htmlFor="name">Nombre</label>
                        <input
                            id="name"
                            type="text"
                            {...register("name", {
                                required: { value: true, message: "Este campo es requerido" },
                                maxLength: { value: 50, message: "Nombre demasiado largo" }
                            })}
                        ></input>
                        {errors?.name && <span className={styles.error}>{errors?.name?.message}</span>}
                    </div>
                    <div className={styles.row}>
                        <label htmlFor="lastname">Apellido</label>
                        <input
                            id="lastname"
                            type="text"
                            {...register("lastname", {
                                required: { value: true, message: "Este campo es requerido" },
                                maxLength: { value: 50, message: "Apellido demasiado largo" }
                            })}
                        ></input>
                        {errors?.lastname && <span className={styles.error}>{errors?.lastname?.message}</span>}
                    </div>
                    <div className={styles.row}>
                        <label htmlFor="email">Email</label>
                        <input
                            id="email"
                            type="email"
                            {...register("email", {
                                required: { value: true, message: "Este campo es requerido" },
                                maxLength: { value: 100, message: "Email demasiado largo" },
                                pattern: {
                                    value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                    message: "Email invalido"
                                }
                            })}
                        ></input>
                        {errors?.email && <span className={styles.error}>{errors?.email?.message}</span>}
                    </div>
                    <div className={styles.row}>
                        <label htmlFor="whatsapp">Whatsapp</label>
                        <input
                            id="whatsapp"
                            type="tel"
                            placeholder="+54 9"
                            {...register("whatsapp", {
                                required: { value: true, message: "Este campo es requerido" },
                                pattern: {
                                    value: /^(0|[1-9]\d*)$/,
                                    message: "Solo numeros"
                                }
                            })}
                        ></input>
                        {errors?.whatsapp && <span className={styles.error}>{errors?.whatsapp?.message}</span>}
                    </div>
                    <div className={styles.rowDisclaimer}>
                        <input
                            htmlFor="emailMarketing"
                            type="checkbox"
                            defaultChecked={checked}
                            onChange={() => setChecked((state) => !state)}
                            {...register("emailMarketing")}
                        />
                        <label id="emailMarketing">Autorizo a NICE a enviarme información por correo electrónico.</label>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.columnHCaptcha}>
                            {!isLocalhost && siteKey && (
                                <>
                                    <HCaptcha sitekey={siteKey} onVerify={(token) => setCaptchaToken(token)} />
                                    {captchaError && <span>{styles.error.message}</span>}
                                </>
                            )}
                        </div>
                    </div>
                    <button
                        className={clsx(styles.btnGrad, {
                            [styles.loading]: formState === formStates.loading,
                            [styles.submitted]: formState === formStates.submitted
                        })}
                        type="submit"
                        value={"Enviar mensaje"}
                        disabled={formState !== formStates.init}
                    >
                        <span className={clsx({ [styles.showMessage]: formState === formStates.init })}>{"INSCRIBIRME"}</span>
                        <span className={clsx({ [styles.showSpinner]: formState === formStates.loading })}>
                            <BeatLoader color="#ffffff" loading margin={2} size={9} speedMultiplier={1} />
                        </span>
                        <span className={clsx(styles.successMessage, { [styles.showSuccess]: formState === formStates.submitted })}>
                            {"INSCRIPCION ENVIADA. ¡Gracias!"}
                        </span>
                    </button>
                </form>
            </section>
        </main>
    );
}

export default MasterClass;
