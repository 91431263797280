// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: process.env.REACT_APP_APIKEY,
    authDomain: process.env.REACT_APP_AUTHDOMAIN,
    projectId: process.env.REACT_APP_PROJECTID,
    storageBucket: process.env.REACT_APP_STORAGEBUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
    appId: process.env.REACT_APP_APPID,
    measurementId: process.env.REACT_APP_MEASUREMENTID
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);

export const existImage = async (path) => {
    try {
        const storage = getStorage();
        const storageRef = ref(storage, path);

        return await getDownloadURL(storageRef);
    } catch (error) {
        switch (error.code) {
            case "storage/object-not-found":
                //console.error("File doesn't exist");
                return false;

            case "storage/unauthorized":
                console.error("User doesn't have permission to access the object");
                return false;

            case "storage/canceled":
                console.error("error storage/canceled");
                return false;

            case "storage/unknown":
                console.error("Unknown error occurred, inspect the server response");
                return false;

            default:
                console.error(error);
                return false;
        }
    }
};

export const replaceImagenInModel = async (model, path = "") => {
    for (const key in model) {
        if (model.hasOwnProperty(key)) {
            const value = model[key];
            const newPath = path ? `${path}.${key}` : key;

            if (typeof value === "object" && value !== null) {
                await replaceImagenInModel(value, newPath);
            } else if (typeof value === "string") {
                const isImage = newPath.includes("images.url") || newPath.includes("image.url") || newPath.includes("image.desktop.url");
                if (isImage) {
                    const imgUrl = await existImage(newPath);
                    model[key] = imgUrl ? imgUrl : "";
                }
            }
        }
    }

    return model;
};

export const newBlob = (event, key) => {
    var file = event.target.files[0];
    var reader = new FileReader();

    reader.onload = function () {
        var blob = new Blob([reader.result], { type: file.type });

        const storage = getStorage();

        const storageRef = ref(storage, key);

        uploadBytes(storageRef, blob).then((snapshot) => {
            console.info("Uploaded a blob or file!", snapshot);
        });
    };

    reader.readAsArrayBuffer(file);
};
