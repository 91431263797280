import { useContext, useState } from "react";
import styles from "./ContactForm.module.scss";
import emailjs from "emailjs-com";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import { useForm } from "react-hook-form";
import clsx from "clsx";
import { MainContext } from "../../contexts";
import BeatLoader from "react-spinners/BeatLoader";

export const formStates = Object.freeze({ init: 0, loading: 1, submitted: 2 });

function ContactForm({ localization }) {
    const [captchaToken, setCaptchaToken] = useState(false);
    const [captchaError, setCaptchaError] = useState(false);
    const [formState, setFormState] = useState(formStates.init);

    const { isLocalhost } = useContext(MainContext);

    const siteKey = process.env.REACT_APP_HCAPTCHA_SITEKEY;

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm();

    function sendEmail(data) {
        if (captchaToken || isLocalhost) {
            captchaError && setCaptchaError(false);
            setFormState(formStates.loading);

            const templateParams = {
                name: data.name,
                email: data.email,
                phone: data.phone,
                message: data.message
            };

            emailjs
                .send(process.env.REACT_APP_EMAILJS_SERVICEID, process.env.REACT_APP_EMAILJS_TEMPLATEID, templateParams, process.env.REACT_APP_EMAILJS_USERID)
                .then(
                    (result) => {
                        setFormState(formStates.submitted);
                        setTimeout(() => {
                            document.getElementById("contact-form").reset();
                            setFormState(formStates.init);
                        }, 10000);
                    },
                    (error) => {
                        console.error(error.text);
                    }
                );
        } else {
            setCaptchaError(true);
            console.error("Captcha incorrect");
        }
    }

    return (
        <article className={styles.container}>
            <h1>Contactanos</h1>
            <p>Contanos en que te podemos ayudar</p>
            <form
                id="contact-form"
                className={clsx(styles.contactForm, { [styles.hideForm]: formState === formStates.submitted })}
                onSubmit={handleSubmit(sendEmail)}
            >
                <div className={styles.row}>
                    <div className={styles.column}>
                        <label htmlFor="name">Nombre*</label>
                        <input
                            name="name"
                            type="text"
                            className={clsx({ [styles.error]: errors?.name })}
                            placeholder={localization?.placeholder?.name ?? "Nombre"}
                            disabled={formState !== formStates.init}
                            {...register("name", {
                                required: { value: true, message: localization?.errors.required ?? "Este campo es requerido" },
                                maxLength: { value: 50, message: localization?.errors.nameTooLong ?? "Nombre demasiado largo" }
                            })}
                        />
                        {errors?.name && <span className={styles.error}>{errors?.name?.message}</span>}
                    </div>
                    <div className={styles.column}>
                        <label htmlFor="name">Email*</label>
                        <input
                            name="email"
                            placeholder={localization?.placeholder?.email ?? "Email"}
                            disabled={formState !== formStates.init}
                            className={clsx({ [styles.error]: errors?.email })}
                            {...register("email", {
                                required: { value: true, message: localization?.errors.required ?? "Este campo es requerido" },
                                maxLength: { value: 100, message: localization?.errors.emailTooLong ?? "Email demasiado largo" },
                                pattern: {
                                    value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                    message: localization?.errors.emailInvalid ?? "Email invalido"
                                }
                            })}
                        />
                        {errors?.email && <span className={styles.error}>{errors?.email?.message}</span>}
                    </div>{" "}
                </div>
                <div className={styles.row}>
                    <div className={styles.column}>
                        <label htmlFor="name">Telefono*</label>
                        <input
                            name="phone"
                            placeholder={localization?.placeholder?.phone ?? "Numero de telefono"}
                            disabled={formState !== formStates.init}
                            className={clsx({ [styles.error]: errors?.phone })}
                            {...register("phone", {
                                pattern: {
                                    value: /^(0|[1-9]\d*)$/,
                                    message: localization?.errors.onlyNumbers ?? "Solo numeros"
                                }
                            })}
                        />

                        {errors?.phone && <span className={styles.error}>{errors?.phone?.message}</span>}
                    </div>
                </div>

                <div className={styles.row}>
                    <div className={styles.column}>
                        <label htmlFor="name">Mensaje*</label>
                        <textarea
                            name="message"
                            placeholder={localization?.placeholder?.message ?? "Mensaje"}
                            className={clsx({ [styles.error]: errors?.message })}
                            disabled={formState !== formStates.init}
                            {...register("message", {
                                required: { value: true, message: localization?.errors.required ?? "Este campo es requerido" },
                                maxLength: { value: 500, message: localization?.errors.descriptionTooLong ?? "Descripción demasiada larga" }
                            })}
                        ></textarea>
                        {errors?.message && <span className={styles.error}>{errors?.message?.message}</span>}
                    </div>
                </div>

                <div className={styles.row}>
                    <div className={styles.columnHCaptcha}>
                        {!isLocalhost && siteKey && (
                            <>
                                <HCaptcha sitekey={siteKey} onVerify={(token) => setCaptchaToken(token)} />
                                {captchaError && <span>{styles.error.message}</span>}
                            </>
                        )}
                    </div>
                </div>

                <div className={styles.footerForm}>
                    <button
                        className={clsx(styles.primaryBtn, {
                            [styles.loading]: formState === formStates.loading,
                            [styles.submitted]: formState === formStates.submitted
                        })}
                        type="submit"
                        value={localization?.button?.sendMessage ?? "Enviar mensaje"}
                        disabled={formState !== formStates.init}
                    >
                        <span className={clsx({ [styles.showMessage]: formState === formStates.init })}>
                            {localization?.button?.sendMessage ?? "Enviar mensaje"}
                        </span>
                        <span className={clsx({ [styles.showSpinner]: formState === formStates.loading })}>
                            <BeatLoader color="#ffffff" loading margin={2} size={9} speedMultiplier={1} />
                        </span>
                        <span className={clsx(styles.successMessage, { [styles.showSuccess]: formState === formStates.submitted })}>
                            {localization?.button?.formSubmitted ?? "Formulario enviado. ¡Gracias!"}
                        </span>
                    </button>
                </div>
            </form>
        </article>
    );
}

export default ContactForm;
