import "./styles/_main.scss";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Index from "./components/Index";
import Admin from "./components/Admin";
import MasterClass from "./components/Forms/MasterClass";
import CursoViajeros from "./components/Forms/CursoViajeros";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <React.StrictMode>
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Index />} />
                <Route path="/admin" element={<Admin />} />
                <Route path="/masterclass-hit-the-road" element={<MasterClass />} />
                <Route path="/curso-ingles-para-viajeros" element={<CursoViajeros />} />
            </Routes>
        </BrowserRouter>
    </React.StrictMode>
);
